import gql from 'graphql-tag';

export const CommentLocationQuery = gql`
	query CommentLocationQuery($commentId: ID, $contentStatus: [GraphQLContentStatus]) {
		comments(commentId: $commentId, contentStatus: $contentStatus) {
			nodes {
				container {
					id
				}
				location {
					type
				}
			}
		}
	}
`;
