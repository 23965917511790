import {
	createPageSegmentLoadMetric,
	INLINE_COMMENTS_HIGHLIGHTS_SEGMENT_KEY,
} from '@confluence/browser-metrics';

// Inline Comments Highlights
export const INLINE_COMMENTS_HIGHLIGHTS_METRIC = createPageSegmentLoadMetric({
	key: INLINE_COMMENTS_HIGHLIGHTS_SEGMENT_KEY,
	timings: [
		{
			key: 'fmp',
			endMark: 'fmp',
		},
		{
			key: 'tti',
			endMark: 'tti',
		},
		{
			key: 'highlightsRendered',
			endMark: 'highlightsRendered',
		},
	],
});
