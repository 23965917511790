import { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { usePageContentId } from '@confluence/page-context';
import { getLogger } from '@confluence/logger';
import type { GraphQLContentStatus } from '@confluence/inline-comments-queries';

import { CommentLocationQuery } from './CommentLocationQuery.graphql';
import type {
	CommentLocationQuery as CommentLocationQueryType,
	CommentLocationQueryVariables,
} from './__types__/CommentLocationQuery';

type InlineCommentQueryParams = {
	queryArchivedContent?: boolean;
};

export type CommentQueryParams = {
	location?: 'FOOTER' | 'INLINE';
	queryArchivedContent?: boolean;
};

export type CommentQueryReturnValues = {
	focusedCommentId?: string;
	editCommentId?: string;
	replyToCommentId?: string;
	shouldOpenNewCommentEditor: boolean;
	shouldMentionInNewCommentEditor: boolean;
	removeCommentQueryParams: () => void;
	setFocusedCommentQueryParam: (commentId: string) => void;
};

type UseCommentQueryParamsProps = (input?: CommentQueryParams) => CommentQueryReturnValues;
type UseInlineCommentQueryParamsProps = (
	input?: InlineCommentQueryParams,
) => CommentQueryReturnValues;

const logger = getLogger('comment-query-params');

export const useCommentQueryParams: UseCommentQueryParamsProps = ({
	location,
	queryArchivedContent = false,
} = {}) => {
	const { getQueryParams, setQueryParams } = useContext(RoutesContext);
	const [pageId] = usePageContentId();

	const {
		focusedCommentId,
		editComment,
		commentId,
		replyToComment,
		showCommentArea,
		shouldMention,
		unreadCommentMark,
	} = getQueryParams();

	const targetCommentId = (focusedCommentId || replyToComment || (editComment && commentId)) as
		| string
		| undefined;
	const shouldSkipQuery = !targetCommentId || !location;

	const { data, error } = useQuery<CommentLocationQueryType, CommentLocationQueryVariables>(
		CommentLocationQuery,
		{
			variables: {
				commentId: targetCommentId,
				contentStatus: [
					queryArchivedContent ? 'ARCHIVED' : 'DRAFT',
					'CURRENT',
				] as GraphQLContentStatus[],
			},
			skip: shouldSkipQuery,
		},
	);

	if (error) {
		logger.error`Error: Unable to query comment location for ${commentId}`;
	}

	let focusedId: string | undefined;
	let editCommentId: string | undefined;
	let replyToCommentId: string | undefined;

	const commentLocation = data?.comments?.nodes?.[0]?.location?.type;
	const commentContainerId = data?.comments?.nodes?.[0]?.container?.id;

	// Only return comment IDs for relevant location; If no location is given, return them all
	if ((!location || location === commentLocation) && pageId === commentContainerId) {
		/* A focused comment query param just uses "focusedCommentId={COMMENT_ID}" */
		focusedId = focusedCommentId ? (focusedCommentId as string) : undefined;

		/* An edit comment query param uses a combination of "editComment=true&commentId={COMMENT_ID}" */
		editCommentId =
			editComment && editComment === 'true' && commentId ? (commentId as string) : undefined;

		/* A reply to comment query param just uses "replyToComment={COMMENT_ID}" */
		replyToCommentId = replyToComment ? (replyToComment as string) : undefined;
	}

	/* PAGE COMMENTS ONLY: A new comment uses the "showCommentArea=true" query param */
	const shouldOpenNewCommentEditor = showCommentArea === 'true';

	/* A mention comment uses the "shouldMention=true" query param */
	const shouldMentionInNewCommentEditor =
		shouldMention === 'true' && replyToCommentId !== undefined;

	const removeCommentQueryParams = () => {
		let commentTypeKey: string = '',
			commentIdKey: string = '',
			commentMentionKey: string = '';
		if (focusedId) {
			commentTypeKey = 'focusedCommentId';
		} else if (shouldMentionInNewCommentEditor) {
			commentTypeKey = 'replyToComment';
			commentMentionKey = 'shouldMention';
		} else if (replyToCommentId) {
			commentTypeKey = 'replyToComment';
		} else if (editCommentId) {
			commentTypeKey = 'editComment';
			commentIdKey = 'commentId';
		} else if (shouldOpenNewCommentEditor) {
			commentTypeKey = 'showCommentArea';
		}

		if (commentTypeKey || commentIdKey || commentMentionKey || unreadCommentMark) {
			const params = getQueryParams();
			setQueryParams({
				...params,
				[commentTypeKey]: undefined,
				[commentIdKey]: undefined,
				[commentMentionKey]: undefined,
				unreadCommentMark: undefined,
			});
		}
	};

	const setFocusedCommentQueryParam = (commentId: string) => {
		const params = getQueryParams();
		setQueryParams({
			...params,
			focusedCommentId: commentId,
		});
	};

	return {
		focusedCommentId: focusedId,
		editCommentId,
		replyToCommentId,
		shouldOpenNewCommentEditor,
		shouldMentionInNewCommentEditor,
		unreadCommentMark,
		removeCommentQueryParams,
		setFocusedCommentQueryParam,
	};
};

export const useInlineCommentQueryParams: UseInlineCommentQueryParamsProps = ({
	queryArchivedContent = false,
} = {}) => useCommentQueryParams({ location: 'INLINE', queryArchivedContent });

export const useFooterCommentQueryParams = () => useCommentQueryParams({ location: 'FOOTER' });
